import React, { useEffect, useRef } from 'react';
import Uncle from '../assets/AskUncle.png'
import './Aboutpopup.css';

const Popup = ({ onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="about-popup-overlay">
      <div ref={popupRef} className="about-popup">
        <img
          src={Uncle}
          alt="Uncle"
          className="about-popup-img"
        />
        <div className="about-popup-header">
          <h2>About Uncle</h2>
        </div>
        <div className="about-popup-description-container">
          <p className="about-popup-description">
            Uncle is a legendary figure in the world of boxing, known for his remarkable achievements and inspiring journey. His story is one of dedication, perseverance, and triumph over adversity.
            <br />
            Born in a small town, Uncle overcame numerous challenges and setbacks to become one of the most celebrated boxers of his era. Despite facing discrimination and financial hardships, he never lost his passion for the sport and his unwavering determination to succeed.
            <br />
            Through his incredible, Uncle became a role model for aspiring boxers.
          </p>
        </div>
        <button onClick={onClose} className="about-popup-close-btn">X</button>
      </div>
    </div>
  );
};

export default Popup;
