import React, { useState, useEffect } from 'react';
import './Popup.css';
import './Addnote.css';
import { supabase } from '../auth/authclient';

const AddNote = ({ onCancel, onSave, onRefreshComplete }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUserId(user.id);
      } else {
        setError('User not logged in');
      }
    };

    fetchUserId();
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();
    if (!title || !content) {
      setError('Both title and content are required!');
      return;
    }

    if (!userId) {
      setError('User not logged in');
      return;
    }

    try {
      setLoading(true);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/notes/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          title,
          content,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setError('');
        setSuccess(true);
        onSave(title, content); // Call the onSave function

        // If onRefreshComplete is passed and is a function, call it
        if (typeof onRefreshComplete === 'function') {
          onRefreshComplete(); // Refresh logic (e.g., refresh sidebar)
        }

        // Trigger a full page reload only after successful save
        window.location.reload();
      } else {
        setError(data.message || 'Failed to save the note. Please try again.');
        setSuccess(false);
      }
    } catch (err) {
      setError('An error occurred while saving the note. Please try again.');
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setTitle('');
    setContent('');
    setError('');
    setSuccess(false);
    onCancel(); // Trigger the cancel action
  };

  return (
    <div className="add-note-overlay">
      <div className="add-note-popup">
        <form onSubmit={handleSave}>
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <textarea
            placeholder="Content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
          <button className="add-note-btn" type="submit" disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">Note saved successfully!</p>}
        <div className="popup-footer">
          <button onClick={handleClose} className="popup-cancel-btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNote;
