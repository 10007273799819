import React, { useState } from 'react';
import { supabase } from './authclient';
import '../components/Popup.css';

const Login = ({ togglePopup, openSignUp }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const { error } = await supabase.auth.signInWithPassword({ email, password });

      if (error) {
        setError(error.message);
        setSuccess(false);
      } else {
        setError('');
        setSuccess(true);
        togglePopup();
        window.location.reload();
      }
    } catch (err) {
      setError('An error occurred while logging in. Please try again.');
      setSuccess(false);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className="popup-btn" type="submit">
            Login
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">Login successful!</p>}
        <div className="popup-footer">
          <p>
            Don't have an account? <span onClick={openSignUp} className="link">
              Sign up
            </span>
          </p>
          <p>
            <span className="link">Forgot Password?</span>
          </p>
        </div>
        <button onClick={togglePopup} className="close-btn">
          x
        </button>
      </div>
    </div>
  );
};

export default Login;
