import React, { useState, useEffect, useRef } from 'react';
import './Sidebar.css';
import { FaHeart, FaEdit, FaSyncAlt, FaTrashAlt, FaSave, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { supabase } from '../auth/authclient'; // Import Supabase client

const Sidebar = ({ isOpen, toggleSidebar, username, refreshSidebar, onRefreshComplete }) => {
  const [combinedItems, setCombinedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const [userId, setUserId] = useState(null);

  // Safeguard for onRefreshComplete to avoid calling undefined function
  const handleRefreshComplete = () => {
    if (typeof onRefreshComplete === 'function') {
      onRefreshComplete();
    }
  };

  const fetchSidebarData = async () => {
    if (!userId) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sidebar?user_id=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCombinedItems(data.data);
      } else {
        setError('Failed to fetch sidebar data');
      }
    } catch (err) {
      setError('Error loading your activity data. Try again later.');
    } finally {
      setLoading(false);
      handleRefreshComplete(); // Calling safely
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return parsedDate.toLocaleString(navigator.language || 'en-US', options);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUserId(user?.id || null);
    };

    fetchUser();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchSidebarData();
    }
  }, [userId]);

  useEffect(() => {
    if (refreshSidebar && userId) {
      fetchSidebarData();
    }
  }, [refreshSidebar, userId]);

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setIsSaved(item.isSaved || false);
  };

  const limitText = (text) => {
    if (!text) return '';
    return text.length > 25 ? text.substring(0, 25) + '...' : text;
  };

  const handleSave = async () => {
    try {
      const responseId = selectedItem.id;
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/search/saved`, {
        response_id: responseId,
        is_saved: !isSaved,
        user_id: userId,
      });

      setIsSaved(!isSaved);
      fetchSidebarData();
    } catch (error) {
      setError('Login to be able to save.');
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setTimeout(() => {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }, 0);
  };

  const handleSaveEdit = async () => {
    try {
      const updatedNote = {
        title: titleRef.current.textContent.trim(),
        content: contentRef.current.textContent.trim(),
        updatedAt: new Date().toISOString(),
      };

      if (!updatedNote.title || !updatedNote.content) {
        setError('Title and content cannot be empty');
        return;
      }

      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/notes/${selectedItem.id}`, updatedNote);

      setSelectedItem({
        ...selectedItem,
        title: updatedNote.title,
        content: updatedNote.content,
        updatedAt: updatedNote.updatedAt,
      });

      setIsEditing(false);
      fetchSidebarData();
    } catch (error) {
      setError('Error updating the note');
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/notes/${selectedItem.id}`);
      fetchSidebarData();
      setSelectedItem(null);
    } catch (error) {
      setError('Error deleting the note');
    }
  };

  const handleKeyDown = (e, type) => {
    if (e.key === 'Enter' && type === 'title') {
      e.preventDefault();
      contentRef.current?.focus();
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    if (titleRef.current && contentRef.current) {
      titleRef.current.textContent = selectedItem.title;
      contentRef.current.textContent = selectedItem.content;
    }
  };

  return (
    <>
      {isOpen && <div className="overlay" onClick={toggleSidebar} />}
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <span className="greeting">Hi {username || 'there!'}</span>
          <a href="/edit-profile" className="edit-profile-link">Edit Profile</a>
          <button className="refresh-button" onClick={() => fetchSidebarData()}>
            <FaSyncAlt size={15} />
          </button>
        </div>

        {loading && <p className="loading-spinner"></p>}
        {error && <p className="error-message">{error}</p>}

        <div className="history-notes-section">
          {combinedItems.length === 0 && !loading && (
            <p>No items to display</p>
          )}

          {combinedItems.map((item, index) => (
            <div
              key={index}
              className={item.type === 'search' ? 'search-card' : 'note-card'}
              onClick={() => handleCardClick(item)}
            >
              {item.type === 'search' ? (
                <>
                  {item.query && <h3 className="search-title">{limitText(item.query)}</h3>}
                  {item.response && <p>{limitText(item.response)}</p>}
                  {item.date && <p className="timestamp">{formatDate(item.date)}</p>}
                  <FaHeart className="icon-bottom-right" />
                </>
              ) : (
                <>
                  {item.title && <h3 className="note-title">{item.title}</h3>}
                  {item.content && <p>{limitText(item.content)}</p>}
                  {item.date && <p className="timestamp">{formatDate(item.date)}</p>}
                  <FaEdit className="icon-bottom-right" />
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {selectedItem && (
        <div
          className="modal-overlay"
          onClick={() => !isEditing && setSelectedItem(null)}
        >
          <div
            className={`modal ${selectedItem.type === 'search' ? 'search-modal' : 'note-modal'}`}
            onClick={(e) => e.stopPropagation()}
          >
            <button 
              className="close-modal" 
              onClick={() => {
                if (isEditing) {
                  handleCancelEdit();
                }
                setSelectedItem(null);
              }}
            >
              x
            </button>
            <div className="modal-content">
              {selectedItem.type === 'search' ? (
                <>
                  <div className="modal-actions">
                    <button onClick={handleSave} className="action-button">
                      {isSaved ? (
                        <>
                          <FaHeart style={{ color: 'goldenrod' }} /> Saved
                        </>
                      ) : (
                        <>
                          <FaHeart /> Save
                        </>
                      )}
                    </button>
                  </div>
                  {selectedItem.date && <p className="notes-timestamp" style={{ fontSize: '0.85rem' }}>Last edited at on {formatDate(selectedItem.date)}</p>}
                  <h2 className="modal-title">{selectedItem.query}</h2>
                  <p>{selectedItem.response}</p>
                </>
              ) : (
                <>
                  <div className="modal-actions">
                    {isEditing ? (
                      <>
                        <button onClick={handleSaveEdit} className="action-button">
                          <FaSave /> Save
                        </button>
                        <button onClick={handleCancelEdit} className="action-button">
                          <FaTimes /> Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button onClick={handleEdit} className="action-button">
                          <FaEdit /> Edit
                        </button>
                        <button onClick={handleDelete} className="action-button">
                          <FaTrashAlt /> Delete
                        </button>
                      </>
                    )}
                  </div>

                  {isEditing ? (
                    <div>
                      <h3
                        contentEditable
                        ref={titleRef}
                        onKeyDown={(e) => handleKeyDown(e, 'title')}
                        className="editable-title"
                      >
                        {selectedItem.title}
                      </h3>
                      <p
                        contentEditable
                        ref={contentRef}
                        className="editable-content"
                      >
                        {selectedItem.content}
                      </p>
                    </div>
                  ) : (
                    <>
                      <h3 className="note-title">{selectedItem.title}</h3>
                      <p>{selectedItem.content}</p>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
