import React from 'react';
import { supabase } from './auth/authclient';
import Home from './pages/home'
import Header from './components/Header'


function App() {
    const user = supabase.auth.getUser();

    return (
        <div className="Home">
            <Header user={user} />
            <Home user={user} />
        </div>
    );
}

export default App;
