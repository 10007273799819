import React, { useState } from 'react';
import { supabase } from './authclient';

const SignUp = ({ togglePopup, openLogin }) => {
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    role: 'user'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState('');

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const validateFields = () => {
    const { email, username, password } = formData;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email || !emailRegex.test(email)) {
      setValidationError('Please enter a valid email address.');
      return false;
    }
    if (!username || username.length < 3) {
      setValidationError('Username must be at least 3 characters long.');
      return false;
    }
    if (!password || password.length < 6) {
      setValidationError('Password must be at least 6 characters long.');
      return false;
    }
    setValidationError('');
    return true;
  };

  const createUserProfile = async (userData) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'apikey': process.env.REACT_APP_SUPABASE_KEY
      },
      body: JSON.stringify(userData)
    });
    const responseText = await response.text();
    let profileResult;
    try {
      profileResult = JSON.parse(responseText);
    } catch {
      throw new Error(`Failed to parse API response: ${responseText}`);
    }
    if (!response.ok) throw new Error(profileResult.message || 'Failed to create user profile');
    return profileResult;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setValidationError('');
    const isValid = validateFields();
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    try {
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            username: formData.username
          }
        }
      });

      if (signUpError) throw signUpError;
      if (!authData?.user) throw new Error('No user data returned from Supabase');

      const profileData = {
        username: formData.username,
        user_id: authData.user.id,
        role: formData.role,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      await createUserProfile(profileData);

      setFormData({ email: '', username: '', password: '', role: 'user' });
      togglePopup();
      alert('Check your email for verification!');
      window.location.reload();  // Trigger a full page reload after signup
    } catch (error) {
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2>Sign Up</h2>
        <form onSubmit={handleSignUp}>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <button
            className="popup-btn"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'Creating Account...' : 'Sign Up'}
          </button>
        </form>
        {validationError && <p className="error-message">{validationError}</p>}
        {error && <p className="error-message">{error}</p>}
        <div className="popup-footer">
          <p>
            Already have an account?{' '}
            <span onClick={openLogin} className="link">Log in</span>
          </p>
        </div>
        <button onClick={togglePopup} className="close-btn">×</button>
      </div>
    </div>
  );
};

export default SignUp;
