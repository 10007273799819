import React, { useState, useEffect } from 'react';
import { supabase } from '../auth/authclient';
import axios from 'axios';
import headerimg from '../assets/AskUncleLogo.png';
import search from '../assets/search-icon.png';
import Popup from './Aboutpopup';
import { FaHeart } from 'react-icons/fa';
import LoginPopup from '../auth/Login';
import SignUpPopup from '../auth/Signup';
import './SearchInput.css';

const SearchInput = () => {
  const [user, setUser] = useState(null);
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isImageVisible, setIsImageVisible] = useState(true);
  const [isResultDisplayed, setIsResultDisplayed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [responseId, setResponseId] = useState(null);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data, error } = await supabase.auth.getUser();
        if (error) {
          console.error('Error fetching user:', error);
        } else if (data) {
          setUser(data.user);  // Set the user data in state
        }
      } catch (err) {
        console.error('Unexpected error fetching user:', err);
      }
    };

    fetchUser();
  }, []);

  const handleSearch = async () => {
    if (!query.trim()) {
      setError('Please enter a question');
      return;
    }

    setLoading(true);
    setError('');
    setIsImageVisible(false);
    setIsResultDisplayed(true);
    setIsSaved(false);

    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/search/`, {
        query: query,
        user_id: user?.id,
      });

      if (res.data && res.data.status === 'success') {
        setResponse(res.data.response);
        setResponseId(res.data.response_id);
        setError('');
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Error:', err);
      setResponse('');
      setError(err.response?.data?.message || 'Failed to fetch response. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (user) {
      try {
        // Perform the save action if user is logged in
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/search/saved`, {
          response_id: responseId,
          is_saved: !isSaved,
          user_id: user.id,
        });
        setIsSaved(!isSaved);
      } catch (error) {
        console.error("Error saving response:", error);
        setError('Failed to save response. Please try again.');
      }
    } else {
      setIsLoginOpen(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleLogoClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const toggleLoginPopup = () => {
    setIsLoginOpen(!isLoginOpen);
  };

  const toggleSignUpPopup = () => {
    setIsSignUpOpen(!isSignUpOpen);
  };

  const openSignUp = () => {
    setIsLoginOpen(false);
    setIsSignUpOpen(true);
  };

  const openLogin = () => {
    setIsSignUpOpen(false);
    setIsLoginOpen(true);
  };

  return (
    <div className="homepage">
      <div className={`search-container ${isResultDisplayed ? 'move-to-top' : ''}`}>
        <img
          src={headerimg}
          className={`headerimg ${isImageVisible ? '' : ''}`}
          alt=""
          onClick={handleLogoClick}
        />

        {showPopup && <Popup onClose={handleClosePopup} />}

        <div className="search-input-container">
          <input
            type="text"
            className="search-input"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ask me anything about boxing..."
            disabled={loading}
          />
          <img
            src={search}
            className="search-icon"
            width="30px"
            alt="search icon"
            onClick={handleSearch}
          />
        </div>
        <div className="response-container">
          {error && <div className="error">{error}</div>}
          {loading ? (
            <div className="response-skeleton"></div>
          ) : (
            response && (
              <div className="response">
                <p className="response-body">{response}</p>
                <div className="save-container">
                  {/* All users see the Save button, but action depends on login status */}
                  <button className="save-button" onClick={handleSave}>
                    {isSaved ? 'Saved' : 'Save'}
                    <FaHeart className="save-icon" />
                  </button>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {isLoginOpen && (
        <LoginPopup togglePopup={toggleLoginPopup} openSignUp={openSignUp} />
      )}
      {isSignUpOpen && (
        <SignUpPopup togglePopup={toggleSignUpPopup} openLogin={openLogin} />
      )}
    </div>
  );
};

export default SearchInput;
